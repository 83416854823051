<template>
  <div id="doctor-messages">
    <el-row>
      <el-col :span="4">
        <Folders />
      </el-col>
      <el-col :span="8">
        <Messages />
      </el-col>
      <el-col :span="12"> 
          <Detail />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Folders from "../../components/messages/Folders.vue";
import Messages from "../../components/messages/Messages.vue";
import Detail from "../../components/messages/Detail.vue";
export default {
  components: { Folders, Messages, Detail },
};
</script>
